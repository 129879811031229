* {
    text-decoration: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    scroll-behavior: initial !important;
}

.no-transition {
    transition: none !important;
}

a, a:hover {
    text-decoration: none;
    color: var(--white);
}

/* --- Website Fonts --- */
@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/Inter-VariableFont_slnt,wght.ttf') format('truetype');
    font-display: swap;
    font-weight: 100 900;
    font-style: normal;
}

@font-face {
    font-family: 'public sans';
    src: url('../fonts/Public_Sans/PublicSans-VariableFont_wght.ttf') format('truetype');
    font-display: swap;
    font-weight: 100 900;
    font-style: normal;
}

@font-face {
    font-family: 'Manrope';
    src: url('../fonts/Manrope/Manrope-VariableFont_wght.ttf') format('truetype');
    font-display: swap;
    font-weight: 200 800;
    font-style: normal;
}


/* --- Body tag start --- */
body {
    padding: 0;
    margin: 0;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: var(--white);
    background-color: var(--dark);
}

.overflowhidden,
body.overflowhidden {
    overflow: hidden;
}

.common-word-wrap {
    word-break: break-word;
}

.scrollbarHidden::-webkit-scrollbar {
    display: none;
}

.common-pe-none{
    pointer-events: none;
}

/* --- Body tag end --- */


/* --- Skeletant Css Start --- */
.skeletant-bx {
    pointer-events: none;
}

.skeletant-design {
    background-color: var(--dark-grey);
    background-image: linear-gradient(45deg, var(--dark-grey) 30%, var(--mint-green-100) 50%, var(--dark-grey) 70%);
    background-position: left;
    background-repeat: repeat;
    background-size: 1200px;
    overflow: hidden;
    transition: all 0.3s ease;
    animation: shine-lines 1s infinite linear;
    pointer-events: none;
    border-radius: 15px;
}

.sh-41{
    height: 41px;
}

@-webkit-keyframes shine-lines {
    0% {
        background-position: -100px;
    }

    100% {
        background-position: 1100px;
    }
}

@keyframes shine-lines {
    0% {
        background-position: -100px;
    }

    100% {
        background-position: 1100px;
    }
}

.table-skeletant-bx .skeletant-design{
    margin: 2px 0;
    border-radius: 0px;
}

.table-skeletant-bx .skeletant-design:last-child {
    margin-bottom: 0;
}
/* --- Skeletant Css End --- */


/* --- Common color status bx start --- */
.status-yellow {
    color: var(--yellow-color) !important;
}
.status-yellow2 {
    background-color: var(--yellow2-color);
    color: var(--yellow-color);
}
/* --- */

.status-green {
    color: var(--green-color) !important;
}

.status-green2 {
    background-color: var(--green2-color);
    color: var(--green-color);
}

.status-green3 {
    background-color: var(--green4-color);
    color: var(--green3-color);
}
/* --- */

.status-red {
    color: var(--red-color) !important;
}

.status-red2 {
    background-color: var(--red2-color);
    color: var(--red-color);
}
/* --- */

.status-blue {
    color: var(--blue-gray) !important;
}

.status-blue2 {
    background-color: var(--grayish-blue);
    color: var(--blue-gray);
}
/* --- */

.status-white {
    color: var(--white) !important;
}

.status-aqua {
    color: var(--aqua-mint) !important;
}

.status-aqua2 {
    color: var(--aqua-mint2) !important;
}
/* --- Common color status bx end --- */



/* --- Custome wrapper start --- */
.custome-wrapper {
    padding: 15px;
    padding-bottom: 50px;
    transition: all 0.3s ease-in-out;
}

@media screen and (min-width: 1200px) {
    .custome-wrapper {
        padding-left: calc(240px + 15px);
    }
    
    .custome-wrapper.cw-smb-mini {
        padding-left: calc(75px + 15px);
    }
}
/* --- Custome wrapper end --- */



/* --- Bootsrep container start --- */
.container-lg.cl-custome {
    max-width: 1140px;
}

.container-lg.cl-custome2 {
    max-width: 1200px;
}

.container-lg.cl-custome3 {
    max-width: 1920px;
    padding: 0;
}
/* --- Bootsrep container end --- */



/* --- Screen loader start --- */
.screen-loader-bx {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--dark);
    z-index: 3;
    transition: all 0.3s ease-in-out;
}

.screen-loader-bx .slb-inner {
    width: 50px;
    height: 50px;
    border: 3px solid var(--lavender-mist);
    border-radius: 50%;
    border-top-color: var(--emerald-green2);
    animation: slb-rotate 2s linear infinite;
}
  
@keyframes slb-rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media screen and (min-width: 1200px) {   
    .custome-wrapper .screen-loader-bx {
        padding-top: 70px;
        padding-left: 240px;
    }

    .custome-wrapper.cw-smb-mini .screen-loader-bx {
        padding-left: 75px;
    }
}
/* --- Screen loader end --- */



/* --- Alert Message start --- */
.alert-text-bx{
    position: relative;
    padding: 15px;
    border-radius: 12px;
}

.alert-text-bx .atb-heading{
    display: flex;
    font-size: 16px;
    font-weight: 600;
    color: currentColor;
    gap: 6px;
}

.alert-text-bx .atb-heading span{
    font-weight: 500;
}

.alert-text-bx .atb-heading svg{
    height: 24px;
    width: 24px;
    position: relative;
    top: -1.5px;
}

.alert-text-bx .atb-text{
    font-size: 14px;
    font-weight: 500;
    opacity: 0.9;
    color: currentColor;
    margin-top: 6px;
}

.alert-wbtn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}

.alert-text-bx .atb-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: fit-content;
    cursor: pointer;
    padding: 8px 16px;
    height: 39px;
    white-space: nowrap;
    border-radius: 10px;
    color: var(--emerald-green);
    font-size: 14px;
    font-weight: 500;
    background-color: var(--white);
    border:1px solid var(--emerald-green4-500);
    transition: all 0.3s ease-in-out;
}

.alert-text-bx.alert-fd-column{
    flex-direction: column;
    justify-content: inherit;
    align-items: inherit;
}

.alert-text-bx.atb-btn-black .atb-btn{
    color: var(--black);
}

.alert-fd-column.alert-text-bx .atb-btn{
    color: var(--black);
}

.alert-text-bx .atb-btn svg{
    width: 18px;
    height: 18px;
    stroke-width: 2px;
}

.alert-text-bx .atb-btn:hover{
    color: var(--white);
    background-color: var(--emerald-green2);
    border:1px solid var(--emerald-green2);
}

@media screen and (max-width:576px) {
    .alert-wbtn{
        flex-direction: column;
        justify-content: inherit;
        align-items: inherit;
    }

    .alert-text-bx .atb-btn{
        width: 100%;
    }
    
}

.alert-style2 .atb-heading{
    align-items: center;
}

.alert-style2 .atb-inner .atb-icon{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    flex:0 0 36px;
    border-radius: 5px;
    z-index: 2;
    overflow: hidden;
}

.alert-style2 .atb-inner .atb-icon::after{
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: currentColor;
    opacity: 0.2;
    z-index: 1;
}
/* --- Alert Message end --- */



/* --- Breadcrumb Start --- */
.breadcrumb-main-bx {
    margin-bottom: 20px;
}

.breadcrumb-heading-bx {
    font-size: 18px;
    color: var(--white);
    font-weight: 500;
    white-space: nowrap;
}

.breadcrumb-heading-bx svg {
    width: 20px;
    height: 20px;
    position: relative;
    top: -2px;
    margin-right: 5px;
    display: none;
}

.breadcrumb-bx {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    white-space: nowrap;
    width: 100%;
    margin-top: 4px;
}

.breadcrumb-bx .breadcrumb-sapretor svg {
    width: 16px;
    height: 16px;
    position: relative;
    top: -1px;
    stroke-width: 2px;
    color: var(--slate-grey);
    margin: 0 6px;
}

.breadcrumb-bx .breadcrumb-link {
    font-size: 14px;
    font-weight: 500;
    /* margin-right: 5px; */
    color: var(--slate-grey);
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.breadcrumb-bx .breadcrumb-link svg{
    width: 18px;
    height: 18px;
    position: relative;
    top: -1px;
}

.breadcrumb-bx .breadcrumb-link.breadcrumb-active {
    font-weight: 500;
    color: var(--white);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    pointer-events: none;
    text-decoration: none;
}

.breadcrumb-bx .breadcrumb-link:hover {
    color: var(--white);
    cursor: pointer;
}
/* --- Breadcrumb End --- */



/* --- Common Box Start --- */
.common-box {
    box-shadow: 0 0 0 1px var(--charcoal-grey);
    background-color: var(--eclipse-black);
    border-radius: 16px;
    padding: 15px;
    position: relative
}

.common-box.cb-color2 {
    border-radius: 12px;
    box-shadow: 0 0 0 1px var(--charcoal-grey2);
    background-color: var(--dark2);
}

.common-box.cb-all-none {
    box-shadow: none;
    border: none;
    background-color: transparent;
    padding: 0;
}

.common-box-heading {
    color: var(--white);
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 15px;
}

.common-box-heading.cbh-16small {
    font-size: 16px;
}

.common-box-heading.cbh-20big {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.6px;
}

.common-box-heading img {
    width: 36px;
    height: 36px;
    margin-right: 4px;
    position: relative;
    top: -2px;
}

.common-box-heading svg {
    width: 24px;
    height: 24px;
    margin-right: 4px;
    position: relative;
    top: -2px;
}

.common-box-heading.cbh-16small svg {
    width: 20px;
    height: 20px;
    top: -2.3px;
}


.common-box-text {
    color: var(--white-800);
    font-size: 14px;
    font-weight: 400;
}

.common-section-heading {
    color: var(--white);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    margin-top: 15px;
}

.common-section-heading.csh-coupon {
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    right: 15px;
    top: 20px;
    text-transform: capitalize;
    margin: 0;
    color: var(--white-500);
    letter-spacing: 0.5px;
}

.common-section-heading.csh-coupon:hover {
    color: var(--emerald-green3);
    text-decoration: underline;
}

.common-section-separator {
    margin: 15px 0;
    width: 100%;
    height: 1px;
    background-color: var(--charcoal-grey);
}

.common-sticky {
    position: sticky;
    top: 85px;
}

@media screen and (max-width:991px) {
    .common-box-heading {
        font-size: 16px;
    }

    .common-box-heading img{
        width: 30px;
        height: 30px;
    }
}
/* --- Common Box End --- */


/* --- Common btn start --- */
.cbi-gap-10px {
    gap: 10px;
}

.common-btn{
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    height: 38px;
    line-height: 38px;
    padding: 0 20px;
    font-size: 14px;
    width: fit-content;
    font-weight: 500;
    white-space: nowrap;
    background: transparent;
    border: 1px solid transparent;
    transition: all 0.3s ease-in-out;
}

.common-btn svg {
    position: relative;
    top: -1px;
    width: 18px;
    height: 18px;
    margin-right: 5px;
}

.common-btn.cb-small {
    font-size: 12px;
    height: 32px;
    min-width: auto;
    padding: 0 10px;
}  

/* --- --- */
.cb-white-fill{
    color: var(--black);
    border-color: var(--white);
    background-color: var(--white);
}

.cb-eg3-fill{
    color: var(--white);
    border-color: var(--emerald-green3);
    background-color: var(--emerald-green3);
}

.cb-w2-fill{
    color: var(--white);
    background-color: var(--white-200);
}

.cwf-am-hover:hover{
    color: var(--white);
    border-color: var(--aqua-mint);
    background-color: var(--aqua-mint);
}

.cwf-w-hover:hover{
    color: var(--black);
    background-color: var(--white);
}


/* --- --- */
.cb-white-outline{
    color: var(--white);
    border-color: var(--white);
}

.cb-wb1-outline{
    color: var(--white);
    border-color: var(--white-100);
}

.cwo-hover:hover{
    color: var(--black);
    background-color: var(--white);
}

@media screen and (max-width:992px) {
    .common-btn {
        padding: 0 10px;
    }  
}

@media screen and (max-width:576px) {
    .common-btn.cb-small {
        padding: 0 10px;
    }  
}
/* --- Common btn end --- */


/* --- Common box info btn start --- */
.common-box-info {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 15px;
    top: 13px;
    border-radius: 50px;
    color: var(--white-700);
    transition: all 0.3s ease-in-out;
}

.common-box-info:hover {
    color: var(--aqua-mint2);
}

.common-box-info svg {
    width: 28px;
    height: 28px;
    flex-shrink: 0;
}
/* --- Common box info btn end --- */



/* --- Calendar navigation start --- */
.calendar-navigation-bx{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.calendar-navigation-bx .cnb-btn{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    flex: 0 0 32px;
    border-radius: 6px;
    color: var(--pale-steel);
    background-color: var(--shadow-grey);
    box-shadow: 0 0 0 1px var(--charcoal-purple);
    padding: 8px;
    transition: all 0.3s ease-in-out;
}

.calendar-navigation-bx .cnb-btn svg{
    width: 12px;
    height: 12px;
    object-fit: contain;
    object-position: center;
}

.calendar-navigation-bx .cnb-date{
    font-size: 14px;
    font-weight: 500;
    min-width: 100px;
    text-align: center;
    white-space: nowrap;
}

@media screen and (min-width:768px) { 
    .calendar-navigation-bx .cnb-btn:hover{
        color: var(--white);
        box-shadow: 0 0 0 1px var(--emerald-green5);
        background-color: var(--emerald-green5);
    }
}

@media screen and (max-width:767px) and (min-width:0px) { 
    .calendar-navigation-bx .cnb-btn:active{
        color: var(--white);
        box-shadow: 0 0 0 1px var(--emerald-green5);
        background-color: var(--emerald-green5);
    }
}
/* --- Calendar navigation end --- */



/* --- Bootsrep modal start --- */
.modal {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

.modal.fade.zoom .modal-dialog {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
}

.modal.zoom.show .modal-dialog {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
}

.custom-content .modal-content {
    border: 1px solid var(--charcoal-grey);
    background-color: var(--eclipse-black);
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
}

.custom-content.cc-mw396px .modal-content {
    max-width: 396px;
    margin: auto;
}

.custom-modal-header {
    position: -webkit-sticky;
    position: sticky;
    padding: 20px 15px 20px;
}

.custom-modal-header .cmh-lable {
    font-size: 20px;
    font-weight: 500;
    color: var(--white);
    text-align: center;
}

.custom-modal-header .cmh-sub-lable {
    color: var(--white-700);
    font-weight: 400;
    font-size: 14px;
    margin-top: 5px;
    text-align: center;
}

.custom-modal-body {
    padding: 0 15px;
}
/* --- Bootsrep modal end --- */



/* --- Close btn start --- */
.close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 35px;
    height: 35px;
    background: transparent;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    color: var(--lavender-mist-700);
    padding: 4px;
    cursor: pointer;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.close-icon:hover {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
}

.close-icon.couponClose{
    top: 5px;
}

@media screen and (min-width:700px) {
    .close-icon:hover {
        background-color: var(--dark-grey);
    }
}

@media screen and (max-width:700px) and (min-width:0px) {
    .close-icon:active {
        background-color: var(--dark-grey);
    }
}
/* --- Close btn end --- */


/* --- Common back btn start --- */
.common-back-btn {
    display: flex;
    justify-content: center;
    gap: 5px;
    align-items: center;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 8px;
    opacity: 0.7;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.common-back-btn:hover {
    opacity: 1;
}

.common-back-btn svg {
    width: 24px;
    stroke-width: 2px;
    height: 24px;
    position: relative;
    top: 1px;
}

.common-back-btn.cbb-absolute {
    position: absolute;
    left: 15px;
    top: 16px;
}

.cbb-80pl {
    padding-left: 80px;
}

/* --- Common back btn End --- */



/* --- Common data bx start --- */
.common-data-bx {
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 15px;
    background-color: var(--dark-grey);
}

.common-data-bx .cdb-item {
    display: flex;
    justify-content: space-between;
    color: var(--white);
}

.common-data-bx .cdb-item .cdb-label {
    color: var(--white-700);
    font-size: 14px;
    font-weight: 500;
}

.common-data-bx .cdb-item .cdb-data {
    position: relative;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
    width: fit-content;
}
/* --- Common data bx end --- */



/* --- Common tabs bx Start --- */
.common-tabs-bx {
    overflow: hidden;
    overflow-x: auto;
    padding-bottom: 1px;
    position: relative;
    white-space: nowrap;
}

.common-tabs-bx::-webkit-scrollbar {
    display: none;
}

.common-tabs-bx::after {
    background-color: var(--quartz);
    content: " ";
    height: 1px;
    left: 0;
    position: absolute;
    top: calc(100% - 1px);
    width: 100%;
}

.common-tabs-bx .ctb-item {
    color: var(--white-700);
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 15px;
    position: relative;
}

.common-tabs-bx .ctb-item::after {
    content: " ";
    position: absolute;
    left: 0;
    top: calc(100% - 1px);
    width: 100%;
    height: 2px;
    opacity: 0;
    z-index: 1;
    border-radius: 50px 50px 0 0;
    background-color: var(--black-100);
    transition: opacity 0.3s ease-in-out;
}

.common-tabs-bx .ctb-item:hover,
.common-tabs-bx .ctb-item.active {
    color: var(--white);
}

.common-tabs-bx .ctb-item:hover::after {
    opacity: 1;
}

.common-tabs-bx .ctb-item.active::after {
    opacity: 1;
    background: var(--emerald-green2);
}
/* --- Common tabs bx end --- */



/* --- Common drop bx start --- */
.common-drop-item{
    position: relative;
    margin-bottom: 10px;
    user-select: none;
}

.common-drop-item .common-drop-bx{
    position: absolute;
    top: calc(100% + 0px);
    right: 0;
    visibility: hidden;
    width: 100%;
    z-index: 2;
}

.common-drop-item .common-drop-bx .common-drop-inner{
    margin-top: 1px;
    border-radius: 8px;
    max-height: 215px;
    background-clip: padding-box;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border:1px solid var(--charcoal-grey);
    background-color: var(--eclipse-black);
    opacity: 0;
    visibility: hidden;
    transform: translateY(1px);
    box-shadow: 0 0 20px 0px rgb(0 0 0 / 40%);
    overflow: hidden;
    overflow-y: auto;
    transition: all 0.3s ease-in-out;
}

.common-drop-item .common-drop-bx .common-drop-inner::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.common-drop-item .common-drop-bx .common-drop-inner::-webkit-scrollbar-track {
    background-color: var(--eclipse-black);
}

.common-drop-item .common-drop-bx .common-drop-inner::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--dark-grey);
}

.common-drop-item .common-drop-bx .common-drop-inner::-webkit-scrollbar-thumb:hover {
    background-color: var(--pale-steel);
}

.common-drop-item.active .common-drop-bx{
    visibility: visible;
}

.common-drop-item.active .common-drop-bx .common-drop-inner{
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease-in-out;
    -webkit-transform: translateY(0px);
       -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
         -o-transform: translateY(0px);
            transform: translateY(0px);
}

.common-drop-item.active .cdi-current .cc-icon svg{
    transform: rotate(-180deg);
}

/* @media screen and (min-width:992px) {
    body.isDropActive{
        overflow: hidden;
        padding-right: 17px;
    }
} */
/* --- Common drop bx end--- */


/* --- Common drop data start --- */
.cdi-current{
    cursor: pointer;
    position: relative;
    background-color: var(--charcoal-grey);
    border: 1px solid var(--charcoal-purple);
    color: var(--white);
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    padding: 10px;
    padding-right: 36px;
    height: 38px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
}

.cdi-current .cc-icon{
    position: absolute;
    right: 0;
    top: 0;
    width: 36px;
    padding: 10px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cdi-current .cc-icon svg{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: all 0.3s ease-in-out;
}

.cdi-item{
    color: var(--lavender-mist-700);
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    padding: 8px 15px;
    position: relative;
    white-space: nowrap;
    transition: all .3s ease-in-out;
}

.ci-tf-capitalize .cdi-current,
.ci-tf-capitalize .cdi-item{
    text-transform: capitalize;
}

.cdi-item:hover{
    color: var(--lavender-mist);
    background-color: var(--lavender-mist-100);
}

.cdi-item.cdi-seleted{
    color: var(--white);
    background-color: var(--emerald-green2);
}
/* --- Common drop data end --- */



/* --- Social icons end --- */
.social-icon-bx{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.social-icon-bx .sib-item{
    width: 48px;
    height: 48px;
}

.social-icon-bx .sib-item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.sib-style-2{
    gap: 5px;
}

.sib-style-2 .sib-item{
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    border-radius: 5px;
    padding: 4px 15px;
    background-color: var(--white);
}

.sib-style-2 .sib-item img{
    width: 20px;
    height: 20px;
}
/* --- Social icons start --- */




/* --- Chart design css start--- */
.Mycharts {
    width: calc(100% + 34px);
    margin-top: -20px;
    margin-bottom: -10px;
    position: relative;
    /* z-index: -1; */
}

.Mycharts2 {
    width: calc(100% + 50px);
    margin-top: -40px;
    margin-bottom: -24px;
    margin-left: -15px;
}



.Mycharts .MuiChartsLegend-series text {
    font-size: 12px !important;
}

.Mycharts .MuiChartsLegend-series .MuiChartsLegend-mark {
    width: 15px !important;
    height: 15px !important;
    transform: translate(5px, 3px);
}

.Mycharts .MuiChartsLegend-series text,
.Mycharts .MuiChartsAxis-label {
    font-family: 'Inter' !important;
    font-weight: 600 !important;
    fill: var(--black) !important;
}

.Mycharts .MuiChartsGrid-verticalLine,
.Mycharts .MuiChartsAxis-line,
.Mycharts .MuiChartsAxis-tick {
    stroke-width: 0 !important;
}

.Mycharts .MuiChartsAxisHighlight-root,
.Mycharts .MuiChartsGrid-horizontalLine{
    stroke-dasharray: 2px !important;
    stroke: var(--white-200) !important;
}

.Mycharts .MuiChartsAxis-tickLabel {
    fill: var(--slate-grey) !important;
    font-family: 'public sans' !important;
    font-weight: 400 !important;
}

.Mycharts .MuiChartsAxis-directionX .MuiChartsAxis-tickLabel {
    transform: translateY(6px) !important;
}

.Mycharts .css-1f57y8b {
    fill: var(--slate-grey);
    color: var(--slate-grey);
}

@media screen and (max-width:576px) {
    .Mycharts {
        pointer-events: none;
    }
}


/* --- --- */
.Mycharts.account-chart{
    width: calc(100% + 50px);
    margin-bottom: 0px;
}

.Mycharts.account-chart .MuiChartsAxis-directionX .MuiChartsAxis-tickLabel {
    transform: translateY(12px) !important;
}

@media screen and (min-width:1024px) {
    .Mycharts.account-chart.line-chart .MuiChartsAxis-directionX g:last-of-type .MuiChartsAxis-tickLabel {
        transform: translateY(12px) translateX(-40px) !important;
    }

    .Mycharts.account-chart.line-chart .MuiChartsAxis-directionX g:first-of-type .MuiChartsAxis-tickLabel {
        transform: translateY(12px) translateX(30px) !important;
    }
}

/* --- chart design end --- */



/* --- Common progress start --- */
.common-progress-bar {
    height: 8px;
    width: 100%;
    background-color: var(--dark);
    overflow: hidden;
    border-radius: 50px;
}

.common-progress-bar .common-progress-inner {
    position: relative;
    height: 100%;
    border-radius: 50px;
    background-color: var(--teal-blue);
    background-image: linear-gradient(90deg, var(--emerald-green), var(--teal-blue));
    transition: all 0.3s ease-in-out;
}

.common-progress-bar .common-progress-inner .common-progress-circle {
    position: absolute;
    right: -7px;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    padding: 6px;
    border: 2px solid var(--pistachio);
    background-color: var(--white);
    border-radius: 50%;
    box-shadow: 0 0 5px #00000038;
}
/* --- Common progress end --- */



/* --- Meter chart start --- */
.meter-chart-bx{
    position: relative;
    max-width: 420px;
    /* max-width: 90%; */
    width: 100%;
    margin: 20px auto 10px;
}

.meter-chart-bx img.mcb-meter-img{
    width: 100%;
}

.meter-chart-bx img.mcb-meter-pointer{
    position: absolute;
    left: 13%;
    bottom: -5px;
    width: 37%;
    transform-origin: right;
    transition: all 0.3s linear;
}

.meter-chart-bx:hover img.mcb-meter-pointer{
    opacity: 0;
    visibility: hidden;
}

.meter-chart-bx .mcb-data-bx{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--grayish-blue);
}

.meter-chart-bx .mcb-data-bx{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 50px 37px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.meter-chart-bx:hover .mcb-data-bx{
    opacity: 1;
    visibility: visible;
}

.meter-chart-bx .mcb-data-bx .mcb-label{
    font-size: 14px;
    font-weight: 500;
    color: var(--grayish-blue);
}

.meter-chart-bx .mcb-data-bx .mcb-value{
    font-size: 24px;
    color: var(--grayish-blue);
}

.meter-chart-bx.dll-meter{
    margin-bottom: 15px;
    cursor: pointer;
}
.meter-chart-bx.dll-meter img.mcb-meter-pointer{
    left: 8%;
    width: 42%;
}
/* --- Meter chart end --- */


/* --- Arc chat start --- */
.arc-item .arc-gauge-bx{
    position: relative;
    padding-bottom: 100%;
}

.arc-item .arc-gauge-bx .MuiGauge-root{
    position: absolute;
    left: 0;
    top: 0;
}

.arc-item .arc-gauge-bx .agb-center{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.arc-item .arc-other-value,
.arc-item .arc-name{
    font-family: 'public sans';
    font-size: 9px;
    font-weight: 400;
    text-align: center;
}

.arc-item .arc-value{
    font-family: 'public sans';
    font-size: 80%;
    text-align: center;
    font-weight: 600;
}

.arc-item .arc-check{
    position: absolute;
    left: 11px;
    bottom: 11px;
    display: flex;
    width: 24px;
    height: 24px;
    color: #FFB024;
    background-color: var(--dark);
    box-shadow: 0 0 0 1px var(--charcoal-grey);
    background-color: var(--eclipse-black);
    border-radius: 50%;
    padding: 6px;
}

.arc-item .arc-check svg{
    width: 100%;
    height: 100%;
}
/* --- Arc chat end --- */



/* --- Custome table Start --- */
.custom-table-responsive {
    border-radius: 16px;
    border: 1px solid var(--charcoal-grey);
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
}

.custom-table thead {
    background-color: var(--dark-grey);
}

.custom-table thead tr th {
    font-weight: 600;
    color: var(--white);
    font-size: 12px;
    white-space: nowrap;
    padding: 10px 15px;
    cursor: pointer;
    font-family: 'Manrope';
    text-transform: uppercase;
    letter-spacing: 1px;
}

/* Table thead (Sorting icons) start */
.custom-table thead tr th .th-sort-icons {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 6px;
    position: relative;
    top: -2px;
    color: var(--white-600);
    border-radius: 3px;
    border: 1px solid var(--slate-grey2);
    width: 18px;
    height: 18px;
    background-color: var(--white-100);
}

.custom-table thead tr th:hover .th-sort-icons,
.custom-table thead tr th.active .th-sort-icons {
    color: var(--white);
}

.custom-table thead tr th .th-sort-icons svg {
    width: 12px;
    height: 12px;
    stroke-width: 3px;
}

.custom-table thead tr th .th-sort-icons svg:nth-child(1) {
    margin-bottom: -2px;
}

.custom-table thead tr th .th-sort-icons svg:nth-child(2) {
    margin-top: -2px;
}

.custom-table thead tr th.asc .th-sort-icons svg:nth-child(1),
.custom-table thead tr th.desc .th-sort-icons svg:nth-child(2) {
    color: var(--emerald-green2);
}
/* Table thead (Sorting icons) end */

.custom-table:not(.off-tr-bg) tbody tr {
    border-top: 1px solid var(--black-100);
    transition: all 0.3s ease-in-out;
}

.custom-table:not(.off-tr-bg) tbody tr:nth-child(odd) {
    background-color: var(--eclipse-black);
}

.custom-table:not(.off-tr-bg) tbody tr:nth-child(even) {
    background-color: var(--dark-grey);
}

.custom-table:not(.off-tr-bg) tbody tr:hover {
    background-color: var(--mint-green-100);
}

.custom-table tbody tr td {
    position: relative;
    padding: 12px 15px;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: var(--white-700);
    white-space: nowrap;
    line-height: normal;
}

.custom-table tbody tr td.td-wrap {
    text-wrap: wrap;
}

.custom-table tbody tr td > svg {
    width: 20px;
    height: 20px;
    margin-right: 6px;
}

.custom-table tbody tr td .td-user{
    display: flex;
    align-items: center;
    gap: 10px;
}

.custom-table tbody tr td .td-user .user-img{
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    object-fit: contain;
    object-position: center;
    display: block;
}

.custom-table tbody tr td .td-user .user-name{
    font-size: 14px;
    color: white;
    font-weight: 500;
}

.custom-table tbody tr td .td-user .user-country{
    margin-top: 2px;
    font-size: 12px;
    display: block;
}

.custom-table tbody tr td .td-img{
    display: flex;
    align-items: center;
    gap: 10px;
}

.custom-table tbody tr td .td-img img{
    width: 24px;
    height: 24px;
}

.custom-table tbody tr td .td-status {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 4px 8px;
    border-radius: 5px;
    line-height: 20px;
    width: 47px;
    height: 22px;
}

.custom-table tbody tr td .td-status.ts-payment {
    width: fit-content;
    border-radius: 50px;
    text-transform: capitalize;
    font-size: 12px;
    border: 1px solid currentColor;
}

.custom-table tbody tr td .td-status.td-dot {
    position: relative;
    padding-left: 20px;
}

.custom-table tbody tr td .td-status.td-dot::after {
    content: " ";
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    background-color: currentColor;
    width: 6px;
    height: 6px;
    border-radius: 50%;
}

.custom-table tbody tr td .td-admin-note {
    width: 200px;
}
/* --- Custome table end --- */



/* --- Pagenation start --- */
.pagination-filter-bx{
    position: relative;
    padding: 15px 0px 0px;
}

.pagination-bx {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination-bx .pb-next,
.pagination-bx .pb-prev{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    min-width: 36px;
    height: 36px;
    padding: 10px;
    border-radius: 8px;
    background-color: var(--charcoal-grey);
    font-family: 'Manrope';
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.6px;
    border: 1px solid var(--charcoal-purple);
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 1px 2px 0px #1018280D;
}

.pb-number-bx .pnb-item{
    min-width: 40px;
    height: 40px;
}

.pagination-bx .pb-next svg,
.pagination-bx .pb-prev svg{
    width: 16px;
    height: 16px;
    object-fit: contain;
    object-position: center;
}

.pagination-bx .pb-next:not(.pb-disabled):hover,
.pagination-bx .pb-prev:not(.pb-disabled):hover{
    color: var(--white);
    border-color: var(--emerald-green);
    background-color: var(--emerald-green);
}

.pagination-bx .pb-next.pb-disabled,
.pagination-bx .pb-prev.pb-disabled{
    cursor: not-allowed;
    opacity: 0.5;
}


.pb-number-bx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3px;
}

.pb-number-bx .pnb-item{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 36px;
    height: 36px;
    padding: 4px;
    border-radius: 8px;
    font-family: 'Manrope';
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.6px;
    border: 1px solid transparent;
    transition: all 0.3s ease-in-out;
}

.pb-number-bx .pnb-item svg{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.pb-number-bx .pnb-item:not(.pnb-current, .pnb-disabled):hover{
    background-color: var(--dark-grey);
    border-color: var(--dark-grey);
}

.pb-number-bx .pnb-item.pnb-current{
    background-color: var(--charcoal-grey);
    border-color: var(--charcoal-purple);
}

.pb-number-bx .pnb-item.pnb-disabled{
    opacity: 0.5;
    margin: 0 -5px;
}

@media screen and (max-width:576px) {
    .pagination-filter-bx{
        padding-top: 65px;
    }
    .pb-number-bx{
        position: absolute;
        top: 16px;
        left: 50%;
        transform: translateX(-50%);
    }
}
/* --- Pagenation end --- */



/* --- Empty box css start ---*/
.empty-div-bx {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    min-height: 190px;
    padding: 30px 10px;
}

.empty-div-bx.edb-border-top{
    border-top: 1px solid var(--charcoal-grey);
}

.empty-div-bx .empty-img {
    width: 70px;
    display: block;
    margin-bottom: 5px;
}

.empty-div-bx .empty-heading {
    line-height: normal;
    color: var(--white);
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.empty-div-bx .empty-sub-heading {
    font-weight: 400;
    color: var(--white-500);
    font-size: 14px;
    text-align: center;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.empty-div-bx .common-btn {
    margin-top: 5px;
}

@media screen and (max-width:576px) {
    .empty-div-bx .empty-heading {
        font-size: 16px;
    }

    .empty-div-bx .empty-sub-heading {
        font-size: 12px;
    }
}
/*--- Empty box css end ---*/


/* --- Notice Start--- */
.legal-content {
    padding: 0 15px 25px;
}

.legal-content .legal-heading {
    font-size: 16px;
    color: var(--white);
    font-weight: 500;
    line-height: 1.5;
}

.legal-content ul li,
.legal-content p {
    font-size: 14px;
    line-height: 1.7;
    margin: 0;
    margin-top: 3px;
    color: #B5B7B7;
    font-weight: 400;
    text-align: left;
}

.legal-content ul.font-16 li {
    font-size: 16px;
    margin-bottom: 15px;
    line-height: 1.6;
}

.legal-content ul {
    list-style: decimal;
    padding: 0 0 0 10px;
    margin: 0;
    margin-left: 10px;
}


.legal-content ul.ul-style-none {
    list-style: none;
    margin: 0;
    padding: 0;
}

.legal-content ul.ul-dick {
    list-style: disc;
}

.legal-content ul.ul-alpha {
    list-style: lower-alpha;
}

.legal-content ul.ul-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.legal-content ul.ul-flex li {
    width: 25%;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding-right: 30px;
    text-align: left;
}


@media screen and (max-width:992px) {
    .legal-content ul.ul-flex li {
        width: 33.33%;
    }
}

@media screen and (max-width:768px) {
    .legal-content ul.ul-flex li {
        width: 50%;
    }
}

@media screen and (max-width:450px) {
    .legal-content ul.ul-flex li {
        width: 100%;
        padding-right: 0px;
    }
}
/* --- Notice End --- */



/* --- Account status start --- */
.account-status-bx{
    position: relative;
    border-radius: 6px;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 500;
    padding-left: 20px;
}

.account-status-bx::after {
    content: " ";
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    background-color: currentColor;
    width: 6px;
    height: 6px;
    border-radius: 50%;
}
/* --- Account status end --- */



/* --- Cdi Account start --- */
.cdi-account .cdi-current{
    display: flex;
    align-items: center;
    gap: 8px;
    height: 44px;
    border-color: var(--charcoal-grey);
    background-color: var(--eclipse-black);
}

.cdi-account .cdi-item{
    display: flex;
    align-items: center;
    gap: 8px;
}
/* --- Cdi Account end --- */







/* --- Challenge Card Bx Start --- */
.challenge-card-bx {
    display: block;
    position: relative;
    border-radius: 12px;
    background: linear-gradient(180deg, var(--teal-blue) 0%, var(--emerald-green) 100%);
    transition: border 0.3s ease-in-out;
    padding: 2px;
    min-height: 137px;
}

.challenge-card-bx .ccb-inner {
    position: relative;
    z-index: 1;
    padding: 15px;
    border-radius: 12px;
    background-color: var(--emerald-green2);
}

.ccd-ss-bx{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.challenge-card-bx .ccb-step {
    display: block;
    border-radius: 50px;
    font-size: 11px;
    font-weight: 500;
    padding: 4px 8px;
    color: var(--white);
    background-color: var(--mint-green-100);
    text-transform: uppercase;
    letter-spacing: 1px;
}


.challenge-card-bx .challenge-top-bx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.challenge-card-bx .challenge-top-bx .ccb-info-bx {
    display: flex;
    align-items: center;
    gap: 10px;
}

.challenge-card-bx .challenge-top-bx .ccb-info-bx .ccb-i-img {
    width: 45px;
    height: 45px;
}

.challenge-card-bx .challenge-top-bx .ccb-info-bx .ccb-i-img img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
}

.challenge-card-bx .challenge-top-bx .ccb-info-bx .ccb-i-data .ccb-i-name {
    color: var(--white);
    font-size: 16px;
    font-weight: 600;
}

.challenge-card-bx .challenge-top-bx .ccb-info-bx .ccb-i-data .ccb-i-type {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--white-800);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2%;
}

.challenge-card-bx .challenge-trade-info {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    gap: 30px;
}

.challenge-card-bx .challenge-trade-info .cti-item {
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
}

.challenge-card-bx .challenge-trade-info .cti-item:not(:last-child):after {
    content: " ";
    position: absolute;
    right: -17px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 70%;
    background-color: var(--white-400);
}

.challenge-card-bx .challenge-trade-info .cti-item .cti-icon {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 4px;
    color: var(--white-800);
}

.challenge-card-bx .challenge-trade-info .cti-item .cti-icon svg {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
}

.challenge-card-bx .challenge-trade-info .cti-data-bx .cti-label {
    color: var(--white-800);
    font-size: 12px;
    font-weight: 500;
}

.challenge-card-bx .challenge-trade-info .cti-data-bx .cti-num {
    color: var(--white);
    font-size: 16px;
    font-weight: 600;
}

.challenge-card-bx .challenge-btns {
    display: none;
    justify-content: flex-end;
    gap: 10px;
    padding: 10px;
}

.challenge-card-bx .challenge-btns .common-btn{
    width: 100%;
}

.challenge-card-bx.active .challenge-btns {
    display: flex;
}



.ccb-request-info {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-radius: 12px;
    padding: 15px;
    text-align: center;
    min-height: 137px;
    font-weight: 500;
    border: 1px solid var(--charcoal-purple);
    background-color: var(--dark-grey);
}

.ccb-request-info.ccb-yellow-ac {
    color: var(--yellow-color);
    border-color: currentColor;
}

.ccb-request-info.ccb-red-ac {
    color: var(--red-color);
    border-color: currentColor;
}

.ccb-request-info.ccb-green-ac,
.ccb-request-info.ccb-real-ac {
    color: var(--emerald-green2);
    border-color: var(--emerald-green2);
}
/* --- Challenge card bx end --- */



/* --- Challenge credentials bx start --- */
.challenge-credentials-bx {
    display: flex;
    flex-direction: column;
    gap: 16px;
    cursor: pointer;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 15px;
    background-color: var(--dark-grey);
}

.challenge-credentials-bx .credential-item .credential-label {
    color: var(--white-700);
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 2px;
}

.challenge-credentials-bx .credential-item .credential-data {
    position: relative;
    color: var(--white);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    width: fit-content;
    padding-right: 35px;
}

.challenge-credentials-bx .credential-item .credential-data .credential-icon {
    cursor: pointer;
    position: absolute;
    display: flex;
    top: 0px;
    right: 4px;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    border-radius: 4px;
    padding: 4px;
    color: var(--white-800);
    border: 1px solid var(--slate-grey2);
    background-color: var(--white-100);
    transition: all .3s ease-in-out;
}

.challenge-credentials-bx .credential-item .credential-data .credential-icon svg {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
    stroke-width: 2px;
}

.challenge-credentials-bx .credential-item .credential-data .credential-icon:hover {
    background-color: var(--emerald-green2);
    border-color: var(--emerald-green2);
    color: var(--white);
}
/* --- Challenge credentials bx end --- */



/* --- Account overview filter start --- */
.account-overview-filter{
    position: absolute;
    right: 15px;
    top: 15px;
}

.aof-chart-filter{
    position: absolute;
    right: 0;
    top: 50px;
    background-color: var(--dark);
    box-shadow: 0 0 0 1px var(--charcoal-purple);
    display: flex;
    border-radius: 8px;
    z-index: 1;
}

.aof-chart-filter:after{
    content: "";
    position: absolute;
    width: 50%;
    height: 100%;
    border-radius: 8px;
    background-color: var(--charcoal-grey);
    z-index: -1;
    transition: all 0.3s ease-in-out;
}

.aof-chart-filter .acf-item{
    width: 50%;
    flex: 0 1 50%;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 500;
    color: var(--silver-grey);
    height: 38px;
    padding: 0 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.aof-chart-filter .acf-item:hover{
    color: var(--white);
}

.aof-chart-filter.lineChart::after{
    left: 0%;
}

.aof-chart-filter.barChart::after{
    left: 50%;
}

.aof-chart-filter.lineChart .acf-item:nth-child(1),
.aof-chart-filter.barChart .acf-item:nth-child(2){
    color: var(--white);
}

@media screen and (max-width:1200px) {
    .account-overview-filter{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
        margin-top: -5px;
    }

    .account-overview-filter .common-drop-item {
        margin-bottom: 0;
        transform: scale(0.9);
        transform-origin: right center;
    }

    .account-overview-filter .aof-chart-filter {
        transform: scale(0.9);
        transform-origin: left center;
    }

    .aof-chart-filter,
    .account-overview-filter{
        position: relative;
        z-index: 1;
        top: initial;
        right: initial;
    }
}
/* --- Account overview filter end --- */



/* --- Account overview css start --- */
.aoi-outer{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 15px;
}

.account-overview-item{
    position: relative;
    padding: 0 15px;
}

.account-overview-item:first-child{
    padding-left: 0;
}

.account-overview-item:last-child{
    padding-right: 0;
}

.account-overview-item:not(:last-child):after{
    content: " ";
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 100%;
    background-color: var(--graphite-grey);
}

.account-overview-item .aoi-label {
    color: var(--white-700);
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.5px;
}

.account-overview-item .aoi-data {
    color: var(--white);
    font-size: 20px;
    font-weight: 400;
    margin-top: 5px;
    display: flex;
    align-items: center;
}

.account-overview-item .aoi-data span{
    color: var(--white);
    font-size: 11px;
    font-weight: 400;
    padding: 2px 6px;
    border-radius: 50px;
    background-color: var(--mint-green-100);
    margin-left: 5px;
}

.account-overview-item .aoi-data.aoi-status-red,
.account-overview-item .aoi-data span.aoi-status-red {
    color: var(--coral-orange);
}

.account-overview-item .aoi-data.aoi-status-green,
.account-overview-item .aoi-data span.aoi-status-green {
    color: var(--aqua-mint);
}

.aoi-outer.aoi-fix-width .account-overview-item{
    width: 25%;
    flex: 0 1 25%;
}

@media screen and (max-width:768px) {
    .aoi-outer.aoi-fix-width .account-overview-item{
        width: 50%;
        flex: 0 1 50%;
    }

    .aoi-outer.aoi-fix-width .account-overview-item:nth-child(2)::after{
        content: none;
    }
    .aoi-outer.aoi-fix-width .account-overview-item:nth-child(3){
        padding-left: 0;
    }
}

@media screen and (max-width:768px) {
    .aoi-outer.aoi-fix-width .account-overview-item{
        width: 50%;
        flex: 0 1 50%;
    }

    .aoi-outer.aoi-fix-width .account-overview-item:nth-child(2)::after{
        content: none;
    }
    .aoi-outer.aoi-fix-width .account-overview-item:nth-child(3){
        padding-left: 0;
    }
}
/* --- Account overview css end --- */



/* --- Dashboard page start --- */

/* --- Dashboard (Welcome bx) start --- */
.welcome-bx {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 16px;
    padding: 15px 15px;
    min-height: 254px;
    padding-bottom: 0;
    background: linear-gradient(93.14deg, var(--emerald-green6) 23.7%, var(--aqua-mint3) 99.23%);
    padding-right: 340px;
}

.welcome-bx .wb-data{
    max-width: 500px;
}

.welcome-bx .wb-data .wb-heading{
    color: var(--white);
    font-size: 28px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 20px;
}

.welcome-bx .wb-data .wb-sub-heading{
    font-size: 16px;
    font-weight: 500;
    color: var(--white-900);
    margin-bottom: 2px;
}

.welcome-bx .wb-data .wb-text{
    font-size: 14px;
    font-weight: 400;
    color: var(--white-900);
}

.welcome-bx .wb-img{
    position: absolute;
    right: 15px;
    bottom: 0;
    width: 300px;
}

@media screen and (max-width:1024px) {
    .welcome-bx {
        padding-right: 300px;
    }
    .welcome-bx .wb-img{
        width: 260px;
    }
}

@media screen and (max-width:992px) {
    .welcome-bx .wb-data .wb-heading{
        font-size: 24px;
    }
    .welcome-bx .wb-data .wb-sub-heading{
        font-size: 14px;
    }
    .welcome-bx .wb-data .wb-text{
        font-size: 12px;
    }
}

@media screen and (max-width:700px) {
    .welcome-bx {
        padding-right: 15px;
    }
    .welcome-bx .wb-data{
        max-width: 100%;
    }
    
    .welcome-bx .wb-img{
        position: static;
        display: block;
        margin: 30px auto 0;
    }
}

@media screen and (max-width:576px) {
    .welcome-bx .wb-btns > *{
        width: 100%;
        padding: 0 10px;
    }
}
/* --- Dashboard (Welcome bx) end --- */


/* --- Dashboard (Help center) start --- */
.cb-help-center{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.cb-help-center .common-box-text{
    max-width: 400px;
    width: 100%;
}

.cb-help-center .chc-right .common-box-heading{
    font-size: 14px;
}

.cb-help-center .chc-right .common-box-heading{
    font-size: 14px;
}

.cb-help-center .sib-style-2{
    justify-content: flex-start;
}

@media screen and (max-width:576px) {
    .cb-help-center{
        flex-direction: column;
        justify-content: inherit;
        align-items: inherit;
    }
}
/* --- Dashboard (Help center) end --- */


/* --- Dashboard (cb subscription bx) start --- */
.cb-subscription-bx{
    position: relative;
    padding-right: 150px;
}

.cb-subscription-bx .csb-data{
    position: relative;
    z-index: 1;
}

.cb-subscription-bx .csb-img{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 200px;
    z-index: 0;

}

@media screen and (max-width:576px) {
    .cb-subscription-bx{
        padding-right: 0px;
    }
    
    .cb-subscription-bx .csb-img{
        opacity: 0.1;
    }
}
/* --- Dashboard (cb subscription bx) end --- */

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */


/* --- Dashboard (current challenge info) start --- */
.current-challenge-info {
    display: flex;
    gap: 15px;
}

.current-challenge-info .cci-left {
    flex-basis: 320px;
    flex-shrink: 0;
}

.current-challenge-info .cci-right {
    flex-grow: 1;
}

@media screen and (max-width:991px) {
    .current-challenge-info .cci-left {
        flex-basis: 300px;
    }
}

@media screen and (max-width:767px) {
    .current-challenge-info {
        flex-direction: column;
    }

    .current-challenge-info .cci-left {
        flex-basis: inherit;
    }

}
/* --- Dashboard (current challenge info) end --- */


/* --- Dashboard (Account info balance) start --- */
.aib-outer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;
}

@media screen and (max-width:576px) {
    .aib-outer{
        flex-direction: column;
        align-items: inherit;
    }

    .aib-outer .common-drop-item{
        width: 100%;
    }
}
/* Dashboard (Account info balance) end */

/* Dashboard (Account info balance) start */
.account-info-balance .aib-name{
    font-size: 14px;
    font-weight: 400;
}

.account-info-balance .aib-balance{
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 34px;
    font-weight: 700;
    line-height: 34px;
    margin-top: 8px;
}

.account-info-balance .aib-balance svg{
    width: 32px;
    height: 32px;
}
/* Dashboard (Account info balance) end */


/* Dashboard (conservative trader bx) start */
.conservative-trader-bx .ctb-img{
    max-width: 180px;
    width: 100%;
    margin: auto;
    display: block;
}

.conservative-trader-bx .ctb-arc-bx{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: 10px;
}

.conservative-trader-bx .ctb-arc-bx .arc-item{
    width: 33.33%;
}

.conservative-trader-bx .ctb-arc-bx .arc-item .arc-gauge-bx{
    padding: 0;
    max-width: 90px;
    width: 100%;
    height: 90px;
    margin: auto;
}
/* Dashboard (conservative trader bx) end */


/* --- Dashboard (Location map start) --- */
.location-map-bx{
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    height: 240px;
}
/* --- Dashboard (Location map end) --- */

/* --- Dashboard page end --- */



/* --- Account detail page start --- */

/* Account detail (account info bx) start */
.account-info-bx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 15px 0 0px;
}

.account-info-bx .aib-inner-bx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.account-info-bx .aib-inner-bx .aib-i-img {
    width: 48px;
    height: 48px;
}

.account-info-bx .aib-inner-bx .aib-i-img img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
}

.account-info-bx .aib-inner-bx .aib-i-data .aib-i-name {
    color: var(--white);
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.account-info-bx .aib-inner-bx .aib-i-data .aib-i-name .account-status-bx{
    position: relative;
    top: -5px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.account-info-bx .aib-inner-bx .aib-i-data .aib-i-created {
    color: var(--white-700);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
}

@media screen and (max-width:720px) {
    .account-info-bx {
        flex-direction: column;
        justify-content: inherit;
        align-items: inherit;
        gap: 15px;
    }
}
/* Account detail (account info bx) end */


/* Account detail (account detail row) start */
.account-detail-row {
    display: flex;
    gap: 15px;
}

.account-detail-row .adr-left {
    flex-grow: 1;
}

.account-detail-row .adr-right {
    flex-basis: 320px;
}

@media screen and (max-width:991px) {
    .account-detail-row .adr-right {
        flex-basis: 300px;
    }
}

@media screen and (max-width:767px) {
    .account-detail-row {
        flex-direction: column;
    }

    .account-detail-row .adr-left {
        flex-grow: inherit;
    }

    .account-detail-row .adr-right {
        flex-basis: inherit;
        flex-shrink: inherit;
    }
}
/* Account detail (account detail row) end */


/* Account detail (account basic info) start */
.account-basic-info {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.account-basic-info .abi-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.account-basic-info .abi-item .abi-label {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white-700);
    font-size: 14px;
    font-weight: 400;
}

.account-basic-info .abi-item .abi-label svg {
    color: var(--white-800);
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.account-basic-info .abi-item .abi-data {
    color: var(--white);
    font-size: 14px;
    font-weight: 500;
}

.account-basic-info .abi-item .abi-data.abi-highlight{
    color: var(--aqua-mint2);
    font-size: 20px;
}

.account-basic-info .abi-item .abi-data span {
    display: block;
    font-size: 12px;
    border-radius: 5px;
    padding: 3px 6px;
    color: var(--majestic-purple);
    background-color: #F4F3FF;
}
/* Account detail (Account basic info) end */


/* Account detail (Limit Card item) start */
.limit-card-bx {
    position: relative;
    padding: 10px 15px;
    border-radius: 12px;
    background-color: var(--dark-grey);
    border: 1px solid var(--black-100);
    height: 100%;
}

.limit-card-bx .lc-heading {
    font-size: 16px;
    font-weight: 600;   
    color: var(--white);
    width: fit-content;
}

.limit-card-bx .lc-time {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    background-color: #def7ec;
    color: #00ab61;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 500;
    padding: 2px 4px;
}

.limit-card-bx .lc-time svg {
    width: 16px;
    height: 16px;
}

.limit-card-bx .limit-card-inner {
    /* padding-right: 50px; */
    margin-top: 10px;
    position: relative;
}

.limit-card-bx .limit-card-inner .lci-left-amount {
    color: var(--white);
    font-size: 16px;
    font-weight: 400;
}

.limit-card-bx .limit-card-inner .lci-left-amount span {
    font-size: 14px;
}

.limit-card-bx .limit-card-inner .lci-percent {
    color: var(--white);
    font-size: 16px;
    font-weight: 500;
    text-align: right;
    margin-top: -24px;
    margin-bottom: 10px;
}

.limit-card-bx .limit-card-inner .common-progress-bar {
    width: calc(100% - 0px);
}

.limit-card-bx .limit-card-inner .lci-data-bx {
    margin-top: 10px;
    color: var(--white-700);
    font-size: 13px;
    font-weight: 400;
}

.limit-card-bx .limit-card-inner .lci-data-bx .ldb-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
}

.limit-card-bx .limit-card-inner .lci-data-bx .ldb-item .li-data{
    color: var(--white);
    font-weight: 500;
}

.limit-card-inner .arc-item .arc-value{
    width: auto;
    font-size: 200%;
    color: var(--grayish-blue);
    font-family: inherit;
    line-height: normal;
}

.limit-card-inner .arc-item .arc-other-value{
    font-size: 80%;
    font-weight: 500;
    color: var(--grayish-blue);
    font-family: inherit;
}

@media screen and (max-width:991px) {

    /* .limit-card-inner .arc-item .arc-gauge-bx{
        padding-bottom: 56%;
    } */

}
/* Account detail (Limit Card item) end */


/* Account detail (Calender bx) start */
.calender-header{
    position: absolute;
    right: 15px;
    top: 10px;
    gap: 10px;
}

.calender-bx{
    margin: 20px 0;
    border-radius: 16px;
    border: 1px solid var(--charcoal-grey);
    overflow: hidden;
}

.calander-days,
.calander-weeks{
    display: flex;
}

.calander-days .cd-item,
.calander-weeks .cw-item{
    flex: 1 1 14.285714%;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--white-700);
    text-align: center;
    border-top: 1px solid var(--charcoal-grey);
    border-right: 1px solid var(--charcoal-grey);
}

.calander-days .cd-item:last-child,
.calander-weeks .cw-item:last-child{
    border-right: none;
}

.calander-days:last-child .cd-item:first-child {
    border-radius: 0 0 0 16px;
}

.calander-days:last-child .cd-item:last-child {
    border-radius: 0 0 16px 0;
}

.calander-weeks .cw-item{
    padding: 15px 5px;
    border-top: none;
}

.calander-days .cd-item{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 5px;
    transition: all 0.3s ease-in;
}

.calander-days .cd-item .cd-i-date{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    margin-bottom: 5px;
    border: 1px solid var(--charcoal-grey);
}

.calander-days .cd-item .cd-i-event{
    color: var(--white-700);
    font-weight: 500;
    font-size: 14px;
}

.calander-days .cd-item:not(.cd-i-disabled, .cd-i-seleted):hover{
    background-color: var(--black-200);
}

.calander-days .cd-item:not(.cd-i-disabled, .cd-i-seleted):hover .cd-i-event{
    color: var(--white);
}

.calander-days .cd-item.cd-i-red .cd-i-date{
    background-color: var(--red-color);
    border-color: var(--red-color);
    color: var(--white);
}

.calander-days .cd-item.cd-i-green .cd-i-date{
    background-color: var(--green-color);
    border-color: var(--green-color);
    color: var(--white);
}

.calander-days .cd-item.cd-i-disabled .cd-i-event,
.calander-days .cd-item.cd-i-disabled .cd-i-date{
    opacity: 0.3;
}

.calander-days .cd-item.cd-i-seleted{
    box-shadow: 0 0 0px 1px var(--yellow-color) inset;
    background-color: var(--black-200);
}

.calander-days .cd-item.cd-i-seleted{
    box-shadow: 0 0 0px 1px var(--yellow-color) inset;
    background-color: var(--black-200);
}

.calander-days .cd-item.cd-i-seleted .cd-i-event{
    color: var(--white);
}

@media screen and (max-width:768px) {
    .calander-days .cd-item .cd-i-event{
        display: none;
    }
}
/* Account detail (Calender bx) end */


/* Account detail (Scale overview item) start */
.scale-overview-item .soi-data-bx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
}

.scale-overview-item .common-progress-bar {
    overflow: initial;
    background-color: var(--quartz);
    box-shadow: 0px 4px 3px 0px var(--black-300) inset;
}

.scale-overview-item .common-progress-bar .common-progress-inner{
    background-color: var(--mantis);
    background-image: linear-gradient(90deg, var(--emerald-green3), var(--mantis));
}

.scale-overview-item .soi-highlight {
    font-size: 16px;
    font-weight: 600;
    width: fit-content;
    margin-left: auto;
    margin-top: 10px;
    letter-spacing: 0.5px;
}
/* Account detail (Scale overview item) end */

/* --- Account detail page end --- */



/* --- New Challenge page start --- */

/* --- New Challenge (Challenge form box .common-box) start --- */
/* .challenge-form-box{
    max-width: 567px;
    width: 100%;
    margin: auto;
} */

.challenge-form-box > .common-box {
    max-width: 500px;
    width: 100%;
    margin: auto;
}

.challenge-form-box > .common-box.cb-866width {
    max-width: 866px;
}
/* --- New Challenge (Challenge form box .common-box) end --- */


/* --- New Challenge (Challenge steps bx) start --- */
.challenge-steps-bx .csb-item,
.challenge-steps-bx {
    display: flex;
    align-items: center;
}

.challenge-steps-bx .csb-item {
    position: relative;
    padding: 0 30px;
    gap: 8px;
}

.challenge-steps-bx .csb-item:first-child {
    padding-left: 0;
}

.challenge-steps-bx .csb-item:last-child {
    padding-right: 0;
}

.challenge-steps-bx .csb-item .csb-i-num {
    width: 28px;
    height: 28px;
    font-size: 15px;
    color: var(--white);
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 0 1px var(--quartz);
    border-radius: 50%;
}

.challenge-steps-bx .csb-item .csb-i-label {
    color: var(--white-700);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.7px;
}

.challenge-steps-bx .csb-item .csb-progress-bar {
    position: absolute;
    top: 50%;
    right: -20px;
    height: 4px;
    width: 40px;
    border-radius: 20px;
    background-color: var(--quartz);
    transform: translateY(calc(-50%));
    overflow: hidden;
}

.challenge-steps-bx .csb-item .csb-progress-bar .csb-progress-inner {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-color: var(--emerald-green3);
    opacity: 0;
}

.challenge-steps-bx .csb-item.complated .csb-i-num,
.challenge-steps-bx .csb-item.active .csb-i-num {
    color: var(--white);
    background-color: var(--emerald-green3);
    box-shadow: 0 0 0 1px var(--emerald-green3);
}

.challenge-steps-bx .csb-item.complated .csb-i-label,
.challenge-steps-bx .csb-item.active .csb-i-label {
    color: var(--white);
}

.challenge-steps-bx .csb-item.complated .csb-progress-bar .csb-progress-inner,
.challenge-steps-bx .csb-item.active .csb-progress-bar .csb-progress-inner {
    opacity: 1;
}

@media screen and (max-width:420px) {
    .challenge-steps-bx .csb-item{
        /* flex-direction: column;
        width: 33.33%; */
        padding: 0 15px;
    }

    .challenge-steps-bx .csb-item .csb-progress-bar {
        right: -7.5px;
        width: 15px;
    }

    .challenge-steps-bx .csb-item .csb-i-num {
        width: 24px;
        height: 24px;
        font-size: 10px;
    }

    /* .challenge-steps-bx .csb-item .csb-i-label {
        font-size: 10px;
    } */
}

@media screen and (max-width:340px) {
    .challenge-steps-bx .csb-item{
        flex-direction: column;
        width: 33.33%;
    }

    .challenge-steps-bx .csb-item .csb-i-label {
        font-size: 10px;
    }
}
/* --- New Challenge (Challenge steps bx) end --- */


/* --- New Challenge (Challenge Detail) start --- */
.challenge-detail {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 100%;
    margin: auto;
}

.challenge-detail .cd-img {
    width: 30px;
    height: 30px;
}

.challenge-detail .cd-heading {
    color: var(--white);
    font-size: 12px;
    font-weight: 400;
    margin-top: 10px;
}

.cd-trader{
    font-size: 12px;
    border-radius: 50px;
    border: 1px solid var(--emerald-green3);
    color: var(--emerald-green3);
    background-color: var(--eclipse-black);
    padding: 1px 6px;
    font-weight: 500;
    width: fit-content;
    margin-top: 10px;
}

.challenge-detail .cd-amount {
    color: var(--emerald-green3);
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
}

.cd-rules-info {
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.cd-rules-info .cd-r-item {
    color: var(--white);
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 5px;
}

.cd-rules-info .cd-r-item svg {
    width: 20px;
    height: 20px;
    color: var(--emerald-green3);
    background-color: var(--dark);
    position: relative;
    top: 0px;
    border-radius: 50%;
    padding: 4px;
}

.challenge-detail .cd-more-info {
    font-size: 12px;
    font-weight: 300;
    color: var(--white);
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.challenge-detail .cd-more-info a {
    color: var(--emerald-green3);
    text-decoration: underline;
    font-weight: 500;
}

.challenge-detail .cd-more-info a:hover {
    color: var(--emerald-green5);
}

.challenge-detail .cd-sgc-ci {
    padding: 0;
    margin-bottom: 15px;
}

.challenge-detail .cd-sgc-ci .checkmark-label {
    font-size: 12px;
    display: flex;
    gap: 6px;
}

.challenge-detail .cd-sgc-ci .checkmark-label .checkmark-icon{
    position: static;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
}

.challenge-detail .common-submit-btn{
    font-size: 14px;
}
/* --- New Challenge (Challenge Detail) end --- */


/* --- New Challenge (Challenge trading rules) start --- */
.challenge-trading-rules {
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 15px;
    background-color: var(--dark-grey);
}

.challenge-trading-rules .ctr-item {
    color: var(--white-700);
    font-size: 14px;
    font-weight: 400;
    display: flex;
    gap: 5px;
}

.challenge-trading-rules .ctr-item a {
    text-decoration: underline;
    color: var(--emerald-green3);
}

.challenge-trading-rules .ctr-item a:hover {
    color: var(--emerald-green5);
}

.challenge-trading-rules .ctr-item svg {
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
    position: relative;
    top: 0px;
    color: var(--emerald-green3);
    background-color: var(--dark);
    border-radius: 50%;
    padding: 4px;
}
/* --- New Challenge (Challenge trading rules) end --- */


/* --- New Challenge (Challenge payment flex) start --- */
.challenge-payment-flex {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.challenge-payment-flex .cpf-left {
    flex-basis: 500px;
    flex-shrink: 0;
}

.challenge-payment-flex .cpf-right {
    flex-basis: 350px;
    flex-shrink: 0;
}

@media screen and (max-width:900px) {
    .challenge-payment-flex {
        align-items: center;
        flex-direction: column-reverse;
    }

    .challenge-payment-flex .cpf-left {
        max-width: 576px;
        width: 100%;
        flex-basis: inherit;
        flex-shrink: inherit;
    }

    .challenge-payment-flex .cpf-right {
        max-width: 576px;
        width: 100%;
        flex-basis: inherit;
        flex-shrink: inherit;
    }
}
/* --- New Challenge (Challenge payment flex) end --- */


/* --- New Challenge (Payment method item) end --- */
.payment-method-item {
    cursor: pointer;
    padding: 0px 10px 0px 0px;
    border-radius: 8px;
    border: 1px solid var(--charcoal-purple);
    background-color: var(--dark);
    display: flex;
    align-items: center;
    gap: 0px;
    transition: all 0.3s ease-in-out;
}

.payment-method-item .pmi-img {
    width: 54px;
    height: 54px;
    flex: 0 0 54px;
    padding: 10px;
    object-position: center;
    object-fit: contain;
}

.payment-method-item .pmi-data-bx .pmi-heading {
    color: var(--white);
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.payment-method-item .pmi-data-bx .pmi-pera {
    color: var(--white-700);
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px;
}

.payment-method-item:hover {
    border-color: var(--emerald-green3);
    box-shadow: 0 0 0px 1px var(--emerald-green3);
}
/* --- New Challenge (Payment method item) end --- */


/* --- New Challenge (Payment tab bx) start --- */
.payment-tab-bx {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
}

.payment-tab-item {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: var(--white-700);
    border: 1px solid var(--charcoal-purple);
    background-color: var(--dark);
    padding: 8px 8px;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    width: 100%;
}

.payment-tab-item .payment-img {
    width: 40px;
    height: 40px;
    padding: 5px;
    object-position: center;
    object-fit: contain;
    border-radius: 50%;
    border: 1px solid transparent;
    margin-right: 10px;
    background-color: var(--white);
    transition: all 0.3s ease-in-out;
}

.payment-tab-item:hover{
    color: white;
    border-color: var(--white-300);
}

.payment-tab-item.active{
    border-color: var(--emerald-green3);
    box-shadow: 0 0 0px 1px var(--emerald-green3);
}

.payment-tab-item.active .payment-img,
.payment-tab-item:hover .payment-img {
    opacity: 1;
}
/* --- New Challenge (Payment tab bx) end --- */


/* --- New Challenge (Payment data bx) start --- */
.payment-data-bx {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.payment-data-bx.pdb-style-3,
.payment-data-bx.pdb-style-2 {
    padding: 15px;
    border-radius: 10px;
    border: 1px solid var(--charcoal-purple);
    background-color: var(--dark);
}

.payment-data-bx .pdb-item .pdb-i-lable {
    color: var(--white-700);
    font-size: 12px;
    font-weight: 400;
    margin-bottom: -3px;
}

.payment-data-bx .pdb-item .pdb-i-data {
    font-size: 14px;
    color: var(--white);
    font-weight: 400;
    margin: 5px 0 0;
}

.payment-data-bx .pdb-item .pdb-i-data.pid-style-2 {
    position: relative;
    padding-right: 34px;
    width: fit-content;
}

.payment-data-bx .pdb-item .pdb-i-data .pdb-i-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    color: var(--white-700);
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    padding: 5px;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: absolute;
    right: 0;
    top: -3px;
}

.payment-data-bx .pdb-item .pdb-i-data .pdb-i-icon:hover {
    color: var(--emerald-green5);
}

.payment-data-bx .pdb-item .pdb-i-data strong {
    color: var(--emerald-green5);
    font-weight: 400;
}

.upi-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.upi-item.pdb-item .pdb-i-lable {
    font-size: 14px;
}

.qr-code-box img {
    max-width: 180px;
    width: 100%;
    display: block;
    margin: auto;
}

.qr-code-box img:nth-child(2) {
    max-width: 320px;
}
/* --- New Challenge (Payment data bx) end --- */


/* --- New Challenge (Payment details) start --- */
.challenge-payment-details .cpd-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid var(--charcoal-purple);
}

.challenge-payment-details .cpd-item:last-child {
    border-bottom: none;
}

.challenge-payment-details .cpd-item .cpd-title {
    color: var(--white-700);
    font-size: 14px;
    font-weight: 400;
}

.challenge-payment-details .cpd-item .cpd-amount {
    color: var(--white);
    font-size: 16px;
    font-weight: 400;
}

.challenge-payment-details .cpd-item.cpd-gt-item {
    padding: 0;
}

.challenge-payment-details .cpd-item.cpd-gt-item .cpd-title {
    color: var(--white);
}

.challenge-payment-details .cpd-item.cpd-gt-item .cpd-amount,
.challenge-payment-details .cpd-item.cpd-gt-item .cpd-title {
    font-size: 20px;
}

.challenge-payment-details .cpd-item.cpd-gt-item .cpd-amount{
    color: var(--aqua-mint2);
}
/* --- New Challenge (Payment details) end --- */

/* --- New Challenge page end --- */



/* --- Leaderboard page start --- */

/* Leaderboard (Best in today) start */
.best-today-row{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.best-today-item{
    width: 33.00%;
    position: relative;
    z-index: 1;
}

.best-today-item .bti-avatar{
    width: 100px;
    height: 100px;
    object-fit: contain;
    object-position: center;
    display: block;
    margin: 0 auto;
    box-shadow: 0px 0px 80px 0px #FFDEA380;
    border-radius: 50%;
}

.best-today-item .bti-name{
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    margin-top: 10px;
}

.best-today-item .bti-inner{
    position: relative;
    padding-top: 15px;
}

.best-today-row .best-today-item:nth-child(1) .bti-inner{
    padding-bottom: 30px;
}

.best-today-row .best-today-item:nth-child(2) .bti-inner{
    padding-bottom: 80px;
}

.best-today-row .best-today-item:nth-child(3) .bti-inner{
    padding-bottom: 10px;
}

.best-today-item .bti-inner::before{
    content: "";
    position: absolute;
    left: 0;
    top: 0px;
    background: linear-gradient(180deg, var(--dark-grey) 0%, var(--dark4) 100%);
    width: 100%;
    height: 40px;
    z-index: -1;
    transform-origin: bottom center;
    transform: perspective(45px) rotatex(20deg);
}

.best-today-item .bti-inner::after{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(180deg, var(--dark-grey) 0%, var(--dark) 93.67%);
    width: 100%;
    height: calc(100% - 40px);
    z-index: -1;
}

.best-today-item .bti-shape-svg{
    width: 100%;
    height: 30px;
}

.best-today-item .bti-badge-img{
    display: block;
    margin: 0 auto;
    width: 56px;
    height: 56px;
    object-fit: contain;
    object-position: center;
    padding: 6px;
    border-radius: 12px;
    margin-bottom: 10px;
    background: radial-gradient(69.52% 69.52% at 50% 69.52%, var(--white-100) 0%, var(--white-500) 100%);
}

.best-today-item .bti-type{
    text-align: center;
    color: var(--white);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
}

.best-today-item .bti-rank-img{
    display: block;
    margin: 0 auto;
    width: 50px;
    height: 50px;
    object-fit: contain;
    object-position: center;
    margin-bottom: 10px;
}

.best-today-item .bti-profit{
    color: var(--emerald-green5);
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.best-today-item .bti-balance{
    color: var(--white-700);
    text-align: center;
    font-size: 14px;
}

@media screen and (max-width:1024px) {
    .best-today-item .bti-avatar{
        width: 80px;
        height: 80px;
    }

    .best-today-item .bti-name{
        font-size: 16px;
    }
}

@media screen and (max-width:576px) {
    .best-today-item .bti-avatar{
        width: 70px;
        height: 70px;
    }

    .best-today-item .bti-name{
        font-size: 12px;
    }

    .best-today-item .bti-badge-img{
        width: 40px;
        height: 40px;
        margin-top: 5px;
    }

    .best-today-item .bti-rank-img{
        width: 36px;
        height: 36px;
    }
    
    .best-today-item .bti-profit{
        font-size: 14px;
    }

    .best-today-item .bti-balance{
        font-size: 12px;
    }
}

/* Leaderboard (Best in today) end */

/* Leaderboard (Best in type) start */
.best-type-bx{
    background-color: var(--eclipse-black);
    padding: 16px;
    border-radius: 12px;
    position: relative;
}

.best-type-bx .btb-heading{
    font-size: 12px;
    font-weight: 600;
    color: var(--white-600);
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 15px;
}

.best-type-bx .btb-user-bx,
.best-type-bx .btb-data,
.best-type-bx .btb-inner{
    display: flex;
    align-items: center;
}

.best-type-bx .btb-inner{
    gap: 10px;
    justify-content: space-between;
}

.best-type-bx .btb-user-bx,
.best-type-bx .btb-data{
    gap: 6px;
}

.best-type-bx .btb-user-bx .btb-user-img{
    width: 40px;
    height: 40px;
}

.best-type-bx .btb-user-bx .btb-name{
    color: var(--white);
    font-size: 14px;
    font-weight: 500;
}

.best-type-bx .btb-user-bx .btb-amount{
    color: var(--white-700);
    font-size: 12px;
    margin-top: 2px;
}

.best-type-bx .btb-data{
    color: var(--emerald-green2);
    font-size: 14px;
    font-weight: 600;
}

.best-type-bx .btb-data svg{
    width: 20px;
    height: 20px;
}

.best-type-bx .btb-badge-img{
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    object-position: center;
    object-fit: contain;
    position: absolute;
    top: 10px;
    right: 10px;
}

@media screen and (max-width:1200px) {
    .best-type-bx .btb-inner{
        flex-direction: column;
        justify-content: inherit;
        align-items: inherit;
    }
    .best-type-bx .btb-badge-img{
        width: 60px;
        height: 60px;
        top: 50%;
        transform: translateY(-50%);
    }
}
/* Leaderboard (Best in type) end */

/* Leaderboard (Best Accounts In Profi) start */
.leaderboard-filter{
    position: absolute;
    right: 0;
    top: -7px;
    width: 120px;
}

.tr-leaderboard{
    border-radius: 0px;
    border: none;
}

.ct-leaderboard{
    border-collapse: separate;
    border-spacing: 0 4px;
}

.ct-leaderboard thead{
    background-color: transparent;
}

.ct-leaderboard thead th{
    border-bottom: 1px solid var(--charcoal-grey);
}

.ct-leaderboard tbody tr td{
    padding: 6px 15px;
    transition: all 0.3s ease-in-out;
}

.ct-leaderboard tbody tr:nth-child(even) td{
    background-color: var(--eclipse-black);
}

.ct-leaderboard tbody tr:hover td{
    cursor: pointer;
    background-color: var(--mint-green-100);
}

.ct-leaderboard tbody tr td:first-child{
    border-radius: 12px 0 0 12px;
}

.ct-leaderboard tbody tr td:last-child{
    border-radius: 0 12px 12px 0;
}

.ct-leaderboard tbody tr td .rank-img{
    width: 31px;
    height: 42px;
    object-fit: contain;
    object-position: center;
    margin: auto;
    display: block;
}

.ct-leaderboard tbody tr td .badge-img{
    width: 50px;
    height: 50px;
    object-fit: contain;
    object-position: center;
    margin: auto;
    display: block;
}

@media screen and (max-width:360px) {
    .leaderboard-filter{
        position: static;
        right: inherit;
        top: inherit;
        width: 100%;
    }
}
/* Leaderboard (Best Accounts In Profi) end */

/* --- Leaderboard page end --- */



/* --- Calendar page start --- */

/* Calendar (filter) start */
.prediction-filter-bx .pfb-drop-bx .pfb-drop-item,
.prediction-filter-bx .pfb-drop-bx,
.prediction-filter-bx{
    display: flex;
    align-items: center;
    gap: 20px;
}

.prediction-filter-bx{
    padding: 10px 15px;
    max-width: 1200px;
    margin: auto;
}

.prediction-filter-bx .calendar-navigation-bx .cnb-date{
    min-width: 177px;
}

.prediction-filter-bx .pfb-drop-bx .pfb-drop-item,
.prediction-filter-bx .pfb-drop-bx{
    width: 100%;
}

.prediction-filter-bx .pfb-drop-bx .pfb-drop-item{
    gap: 10px;
}

.prediction-filter-bx .pfb-drop-bx .pfb-drop-item .sgr-outer-label{
    font-size: 12px;
    margin: 0;
}

.prediction-filter-bx .pfb-drop-bx .pfb-drop-item .common-drop-item {
    margin-bottom: 0;
    width: 100%;
}

.prediction-filter-bx .pfb-drop-bx .pfb-drop-item .cdi-current {
    font-weight: 400;
    background-color: var(--dark);
}
/* Calendar (filter) end */

/* Calendar (prediction table) start */
.tr-prediction{
    border-top: 1px solid var(--charcoal-grey);
}

.tr-prediction thead tr th{
    padding: 0;
    opacity: 0;
}

.tr-prediction thead tr th.th-time{
    width: 60px;
}

.ct-prediction tbody tr:not(:last-child) td{
    border-bottom: 1px solid var(--charcoal-grey);
}

.ct-prediction tbody tr td.td-ctp-time{
    position: sticky;
    left: 0;
    z-index: 1;
    min-height: 100px;
    padding: 0px;
}

.ct-prediction tbody tr td.td-ctp-time::after{
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--eclipse-black);
    border-right: 1px solid var(--charcoal-grey);
}

.ct-prediction tbody tr:last-child td.td-ctp-time::after{
    border-radius: 0 0 0 16px;
}

.ct-prediction tbody tr td.td-ctp-time .ctp-line{
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: var(--charcoal-grey);
}

.ct-prediction .ctp-time{
    position: relative;
    z-index: 1;
    width: 60px;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    letter-spacing: 1px;
    color: var(--slate-grey);
    font-weight: 500;
    text-transform: uppercase;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg);
}

.ct-prediction .ctp-item-bx{
    display: flex;
    gap: 15px;
}

.prediction-card-item{
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 320px;
    flex: 0 0 320px;
    border-radius: 12px;
    padding: 12px;
    background-color: var(--dark-grey);
    transition: all 0.3s ease-in-out;
}

.prediction-card-item:not(.pci-full):hover{
    background-color: var(--mint-green-100);
}

.prediction-card-item.pci-full{
    width: 100%;
    cursor: inherit;
}

.prediction-card-item .pci-impact{
    position: absolute;
    right: 12px;
    top: 10px;
    border-radius: 6px;
    padding: 6px 8px;
    font-size: 12px;
    font-weight: 600;
}

.prediction-card-item .pci-event,
.prediction-card-item .pci-currency{
    font-size: 14px;
    font-weight: 500;
    color: var(--emerald-green5);
    letter-spacing: 1px;
}

.prediction-card-item .pci-event{
    color: var(--white);
    margin-top: 8px;
    /* word-break: break-word; */
    white-space: normal;
    line-height: 1.6;
    font-size: 12px;
    font-weight: 400;
}

.prediction-card-item .pci-separator{
    width: 100%;
    height: 1px;
    background-color: var(--white-100);
}

.prediction-card-item .pci-data{
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 400;
    color: var(--white-700);
}

.prediction-card-item .pci-data span{
    color: var(--white);
}

.prediction-card-item .pci-text{
    white-space: normal;
    font-size: 12px;
    line-height: 1.6;
    text-align: justify;
}
/* Calendar (prediction table) end */

@media screen and (max-width:700px) {
    .prediction-filter-bx {
        flex-direction: column;
        align-items: flex-start;
    }
}

@media screen and (max-width:576px) {
    .prediction-filter-bx .pfb-drop-bx{
        flex-direction: column;
        align-items: flex-start;
    }

    .prediction-filter-bx .pfb-drop-bx .pfb-drop-item .sgr-outer-label{
        width: 70px;
    }

    .prediction-filter-bx .calendar-navigation-bx {
        justify-content: space-between;
        width: 100%;
    }


    .ct-prediction .ctp-time,
    .tr-prediction thead tr th.th-time{
        width: 50px;
    }

    .ct-prediction .ctp-time{
        font-size: 12px;
    }

    .prediction-card-item{
        width: 260px;
        flex: 0 0 260px;
    }

    .prediction-card-item .pci-event{
        font-size: 14px;
    }
}
/* --- Calendar page end --- */


/* --- Affiliate data item start --- */

/* Affiliate (Referral Code) start */
.referral-btn{
    flex-direction: column;
    justify-content: inherit;
    align-items: inherit;
}

.referral-btn .atb-btn{
    padding: 6px;
    height: auto;
    white-space: wrap;
    word-break: break-all;
    justify-content: inherit;
    color: black;
}

.referral-btn .atb-btn svg{
    width: 32px;
    height: 32px;
    flex: 0 0 32px;
    padding: 8px;
    border-radius: 5px;
    color: var(--white);
    background-color: var(--onyx-black);
}
/* Affiliate (Referral Code) end */


/* Affiliate (affiliate program bx) start */
.affiliate-program-bx{
    padding: 10px;
}

.affiliate-program-bx .atb-heading{
    font-weight: 400;
}

.affiliate-program-bx .atb-btn{
    color: var(--white);
    border-color: var(--emerald-green);
    background-color: var(--emerald-green2);
}

.affiliate-program-bx .atb-btn:hover{
    background-color: var(--emerald-green5);
}
/* Affiliate (affiliate program bx) end */


/* Affiliate (Data item) start */
.affiliate-data-item{
    position: relative;
    border: 1px solid var(--charcoal-purple);
    background-color: var(--dark-grey);
    border-radius: 12px;
    padding: 20px;
    padding-left: 70px;
}

.affiliate-data-item .adi-icon{
    position: absolute;
    left: 20px;
    top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    color: var(--white);
    background-color: var(--emerald-green2);
}

.affiliate-data-item .adi-icon svg{
    width: 24px;
    height: 24px;
    object-position: center;
    object-fit: contain;
}

.affiliate-data-item .adi-heading{
    color: var(--grayish-blue);
    font-size: 12px;
    font-weight: 500;
}

.affiliate-data-item .adi-data{
    color: var(--white);
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    margin-top: 6px;
    margin-bottom: -6px;
}
/* Affiliate (Data item) end */


/*  */
.affiliate-balance{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    border-radius: 8px;
    border: 1px solid var(--charcoal-grey);
    background-color: var(--dark);
    padding: 8px 15px;
    gap: 8px;
}

.affiliate-balance .ab-balance,
.affiliate-balance .ab-name{
    color: var(--lavender-mist);
    font-weight: 400;
    font-size: 18px;
    opacity: 0.7;
}

.affiliate-balance .ab-balance{
    color: var(--aqua-mint2);
    opacity: 1;
    font-weight: 600;
}
/*  */

/* --- Affiliate page end --- */