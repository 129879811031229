.signinup-main-box{
    display: flex;
    margin: 0 auto;
    max-width: 1440px;
    position: relative;
    z-index: 1;
    min-height: 100vh;
}


/* --- Smb side img start --- */
.signinup-main-box .smb-side-img{
    /* display: none; */
    width: auto;
    height: 100vh;
    object-position: center;
    object-fit: contain;
    padding: 15px;
    position: sticky;
    top: 0;
}
/* --- Smb side img end --- */


/* --- Smb content box start --- */
.signinup-main-box .smb-content-box{
    flex-grow: 1;
    max-width: 64%;
}

.signinup-main-box .smb-content-box .smb-content-wraper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100% - 71px);
}

/* --- Smb content (signinup header) start --- */
.signinup-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
}

.signinup-logo-bx{
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.signinup-logo-bx img.logo-icon{
    width: 40px;
    height: 40px;
    object-fit: contain;
    object-position: center;
    display: block;
}

.signinup-logo-bx img.logo-text{
    width: 136px;
}
/* --- Smb content (signinup header) end  --- */


/* --- --- */
.signinup-screen {
    max-width: 500px;
    width: 100%;
    position: relative;
    margin: auto;
    padding: 15px;
}

/* --- Screen heading --- */
.screen-header{
    margin-top: 8px;
    margin-bottom: 18px;
}

.screen-hed {
    color: var(--white);
    font-weight: 600;
    font-size: 24px;
    text-align: left;
}

.screen-pera {
    color: var(--white-700);
    font-weight: 400;
    font-size: 12px;
    text-align: left;
    margin-top: 2px;
}


/* --- --- */
.signinup-link-outer-bx{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    font-size: 14px;
}

.slob-fdc{
    gap: 1px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.slob-fdc.fdc-two{
    gap: 5px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.signinup-link{
    color: var(--white-700);
    display: block;
    font-size: 12px;
    font-weight: 400;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    cursor: pointer;
}

.signinup-link:hover{
    color: var(--white);
    text-decoration: underline;
}

.signinup-link-two{
    cursor: pointer;
    color: var(--white-700);
    display: block;
    font-size: 14px;
    font-weight: 400;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.signinup-link-two:hover{
    color: var(--white);
    text-decoration: underline;
}



@media screen and (max-width:768px) {
    .signinup-main-box{
        flex-direction: column;
        gap: 25px;
    }

    .signinup-main-box .smb-side-img{
        max-width: 500px;
        width: 100%;
        height: auto;
        margin: 0 auto;
    }

    .signinup-main-box .smb-content-box{
        max-width: 100%
    }
    
}