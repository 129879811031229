/* ---
Flash Message Popup Start
--- */
.flash-message-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -moz-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 10px 10px 50px;
  opacity: 0;
  visibility: hidden;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background: #0000004d;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.flash-message-popup.fmp-active {
  opacity: 1;
  visibility: visible;
}

.flash-message {
  border: 1px solid var(--charcoal-grey);
  background-color: var(--eclipse-black);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 15px 40px 15px 15px;
  max-width: 400px !important;
  width: 95%;
  position: relative;
  position: fixed;
  left: 50%;
  bottom: 50px;
  -webkit-transform: translate(-50%, 20px);
  -moz-transform: translate(-50%, 20px);
  -ms-transform: translate(-50%, 20px);
  -o-transform: translate(-50%, 20px);
  transform: translate(-50%, 20px);
  opacity: 0;
  visibility: hidden;
  z-index: 1101;
  -webkit-box-shadow: 0 0 15px #0000003d;
  -moz-box-shadow: 0 0 15px #0000003d;
  box-shadow: 0 0 15px #0000003d;
  -webkit-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  overflow: hidden;
}

.flash-message-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.flash-message.fmp-message {
  -webkit-transform: translate(-50%, 0px);
  -moz-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  -o-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
  opacity: 1;
  visibility: visible;
}

.flash-message .flash-img {
  width: 30px;
  height: 30px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 30px;
  -moz-box-flex: 0;
  -ms-flex: 0 0 30px;
  flex: 0 0 30px;
  margin-right: 10px;
  -webkit-align-self: self-start;
  -ms-flex-item-align: self-start;
  -ms-grid-row-align: self-start;
  align-self: self-start;
}

.flash-message .flash-error-svg,
.flash-message .flash-success-svg {
  width: 40px;
  height: 40px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 40px;
  -moz-box-flex: 0;
  -ms-flex: 0 0 40px;
  flex: 0 0 40px;
  margin-right: 10px;
  -webkit-align-self: self-start;
  -ms-flex-item-align: self-start;
  -ms-grid-row-align: self-start;
  align-self: self-start;
}

.flash-message .flash-success-svg {
  color: var(--green-color);
}

.flash-message .flash-error-svg {
  color: var(--red-color);
}

.flash-message .flash-text {
  color: var(--white);
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  margin: 0;
}

.flash-time-line {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
  background-color: var(--green-color);
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.fmp-message .flash-time-line {
  -webkit-animation: flashtimeline 3s forwards;
  -moz-animation: flashtimeline 3s forwards;
  -o-animation: flashtimeline 3s forwards;
  animation: flashtimeline 3s forwards;
}

@-webkit-keyframes flashtimeline {
  0% {
    width: 100%;
    opacity: 1;
  }

  100% {
    width: 0%;
    opacity: 1;
  }
}

@-moz-keyframes flashtimeline {
  0% {
    width: 100%;
    opacity: 1;
  }

  100% {
    width: 0%;
    opacity: 1;
  }
}

@-o-keyframes flashtimeline {
  0% {
    width: 100%;
    opacity: 1;
  }

  100% {
    width: 0%;
    opacity: 1;
  }
}

@keyframes flashtimeline {
  0% {
    width: 100%;
    opacity: 1;
  }

  100% {
    width: 0%;
    opacity: 1;
  }
}
