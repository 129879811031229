.custom-header{
    padding: 0 15px;
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 125;
    background-color: var(--dark);
    border-bottom: 1px solid var(--charcoal-grey);
    box-shadow: 0 0 10px var(--black-200);
}

.custom-header .ch-inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
}


.custom-header .ch-inner .ch-left{
    display: flex;
    gap: 15px;
}

.custom-header .ch-inner .ch-right{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}


/* Header Logo Start */
.custom-header .ch-inner .ch-left .ch-logo-bx{
    display: none;
    align-items: center;
    gap: 10px;
}

.custom-header .ch-inner .ch-left .ch-logo-bx .logo-icon{
    position: relative;
    right: 0;
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    transition: right 0.3s ease-in-out;
}

.custom-header .ch-inner .ch-left .ch-logo-bx .logo-text{
    width: 130px;
    height: 20px;
    object-position: left center;
    object-fit: contain;
    transition: opacity 0.3s ease-in-out;
}

@media screen and (max-width:1200px) {
    .custom-header .ch-inner .ch-left .ch-logo-bx{
        display: flex;
    }
}

@media screen and (max-width:390px) {
    .custom-header .ch-inner .ch-left .ch-logo-bx .logo-text{
        display: none;
    }
}
/* Header Logo End */

/* --- --- */
.cr-theme-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.cr-theme-btn .cr-tb-lable{
    color: var(--white);
    font-size: 14px;
    letter-spacing: 1px;
    position: relative;
    top: -1px;
}

/*  */
@media screen and (max-width:768px) {
    .cr-theme-btn.ctb-sch-btn .cr-tb-lable{
        display: none;
    }
}

@media screen and (max-width:576px) {
    .cr-theme-btn .cr-tb-lable{
        display: none;
    }
}
/*  */

.cr-icons-link{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 8px;
    color: var(--pale-steel);
    background-color: var(--shadow-grey);
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    position: relative;
    z-index: 1;
}

.cr-icons-link .cr-num{
    position: absolute;
    right: 10px;
    top: 9px;
    width: 10px;
    height: 10px;
    font-size: 10px;
    font-weight: 500;
    border-radius: 50px;
    border: 2px solid var(--shadow-grey);
    background: #DE2C41;
    box-shadow: 0px 1px 2px 0px #E4E5E73D;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cr-drop-item:hover > .cr-icons-link,
.cr-icons-link:hover{
    color: var(--white);
    background-color: var(--emerald-green);
}

@media screen and (max-width:380px) {

    .custom-header {
        padding: 0 15px;
    }
    
    .custom-header .ch-inner .ch-left {
        gap: 10px;
    }

}

/* --- Aside Menu Icon Start --- */
.menu-icon-bx {
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--shadow-grey);
}

/* .menu-icon-bx:hover{
    background-color: var(--pale-steel);
}

.menu-icon-bx.active{
    background-color: var(--white);
} */

.menu-icon-bx span {
    display: block;
    width: 100%;
    height: 2.2px;
    -webkit-border-radius: 100px;
       -moz-border-radius: 100px;
            border-radius: 100px;
    opacity: 0.8;
    background-color: var(--pale-steel);
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.menu-icon-bx span:nth-child(2) {
    width: 60%;
    margin-right: auto;
}


.menu-icon-bx:hover span,
.menu-icon-bx.active span{
    opacity: 1;
    background-color: var(--white);
}

.menu-icon-bx.active span:nth-child(1) {
    width: 50%;
    margin-left: auto;
}

.menu-icon-bx.active span:nth-child(2) {
    width: 90%;
}

.menu-icon-bx.active span:nth-child(3) {
    width: 50%;
    margin-right: auto;
}
/* --- Aside Menu Icon End --- */


/* --- */
.smb-min-btn{
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 20px;
    left: -12.5px;
    width: 25px;
    height: 25px;
    padding: 5px;
    border-radius: 5px;
    color: var(--white);
    background-color: var(--onyx-black);
    border: 1px solid var(--charcoal-grey);
    z-index: 1;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.smb-min-btn:hover{
    background-color: var(--emerald-green);
    border-color: var(--emerald-green);
}

.smb-min-btn svg{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    stroke-width: 3px;
    transition: transform 0.3s ease-in-out;
}
/* --- */


/* --- side menu min css start --- */
@media screen and (min-width:1200px) {
    .custom-header{
        margin-left: auto;
        width: calc(100% - 240px);
        transition: width 0.3s ease-in-out;
        z-index: 127;
    }

    .custom-header.cw-smb-mini{
        width: calc(100% - 75px);
    }

    .custom-header .smb-min-btn{
        display: flex;
    }

    .custom-header.cw-smb-mini .smb-min-btn svg{
        transform: rotate(180deg);
    }

    .menu-icon-bx {
        display: none;
    }
}
/* --- side menu min css end --- */